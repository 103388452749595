.navigation {
  width: 240px;
  padding-left: 40px;
  position: relative;
  background: #fff;
  transition: 0.3s;

  &__guidanceIcon {
    width: 16px;
    height: 16px;
  }
  h2 {
    margin-top: 10px;
    font-size: 24px;
    // color: #436587;
    color: #fff;
    text-align: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .navigation__item {
      width: 100%;
    }
  }
  &__item {
    display: block;
    font-size: 14px;
    text-align: center;
    padding-left: 0;
    // color: #666666;
    color: #fff;
    height: 56px;
    line-height: 56px;
    transition: 0.3s;
    opacity: 1;
    visibility: visible;

    &--selected {
      background: #fff;
      color: #436587;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 100%;
        left: 0;
        top: 0;
        background: #436587;
      }
    }
  }

  &__footer {
    position: absolute;
    left: 70px;
    bottom: 8px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
}

@media screen and (max-width: 1001px) {
  .navigation {
    position: fixed;
    left: calc(100vw - 240px);
    width: 240px;
    z-index: 2;
    padding: 0;

    box-shadow: 0px 6px 7px 0px rgba(0, 0, 0, 0.08);
    &__unfold {
      left: 100vw !important;
      width: 0;
    }
    &__item {
      padding-left: 30px;
    }
    &__subItem {
      font-size: 16px;
      padding-left: 0;
    }
    &__guidance {
      position: absolute;
      top: -34px;
      right: 40px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
    &__h2 {
      display: block;
      width: 240px;
      &--unfold {
        display: none;
      }
    }
    &__content {
      display: block;
      width: 240px;
      height: calc(100vh - 148px);
      overflow: auto;
      &--unfold {
        display: none;
      }
    }

    &__switch {
      position: absolute;
      right: 0;
      top: -46px;
      color: #fff;
      // border-top: 1px solid #e3e3e3;
      text-align: center;
      font-size: 16px;
      height: 36px;
      line-height: 36px;
      transition: 0.3s;
      // background: #fff;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 0 2px 2px 0;

      &--unfold {
        text-align: right;
        padding-right: 10px;
      }
    }

    &__footer {
      // position: absolute;
      // font-size: 16px;
      // display: block;
      position: initial;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      height: 40px;
      color: #fff;
      cursor: pointer;
      &--unfold {
        display: none;
      }
    }
  }
}
