.calendar-layout {
  height: 100%;
  &__content {
    height: calc(100vh - 80px);
    overflow: hidden;
    z-index: 99;
  }

  &__catalog {
    height: calc(100vh - 80px);
    margin-top: 80px;
    float: left;
    background-color: rgba(67, 101, 135, 0.9);
    position: absolute;
    top: 0;
    z-index: 10;
    color: #fff;
    padding-left: 0px;
    box-shadow: 0px 0px 43px 0px rgba(30, 63, 96, 0.68);
    // padding: 0 26px 16px 26px;
  }
  &__catalogContainer {
    // height: calc(100vh - 80px);
    height: 100vh;
    float: left;
    width: 240px;
    position: absolute;
    top: 0;
    // border-right: 1px solid #e3e3e3;
    background: url("./assets/bg.jpg");
    background-color: rgba(67, 101, 135, 0.9);
  }

  &__main {
    height: 100%;
    overflow: auto;
    padding: 0px 66px 56px;
    margin-left: 240px;
  }
}

@media screen and (max-width: 1001px) {
  .calendar-layout {
    &__content {
      height: calc(100vh - 50px);
    }
    &__catalog {
      height: calc(100vh - 50px);
      margin-top: 50px !important;
      box-shadow: none;
      z-index: 1001;
    }
    &__main {
      padding: 0 !important;
      // padding: 0px 32px;
      margin-left: 0 !important;
    }
    &__catalogContainer {
      width: 0;
    }
    &__cover {
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.65);
      opacity: 0.3;
      z-index: 9;
      position: absolute;
    }
  }
}
