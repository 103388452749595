.loadable-loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow: hidden;
  img {
    width: 100px;
    height: 100px;
    top: 30vh;
    position: absolute;
    z-index: 1;
    transition: opacity 0.3s;
  }
}
