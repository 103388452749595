.guidance {
  width: 100vw;
  height: 100vh;
  left: 0;
  position: fixed;
  z-index: 9999999;
  &_dec {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    z-index: 999999;
    position: absolute;
  }
  &_arrows {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    z-index: 999999;
    position: absolute;
    img {
      width: 68px;
      height: 68px;
    }
  }
  &_top {
    width: 100vw;
    // height: 100px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 999999;
  }
  &_left {
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    z-index: 999999;
  }

  &_right {
    // height: 100px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 999999;
  }

  &_bottom {
    // height: 100px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 999999;
    bottom: 0;
  }
  &_btns {
    position: absolute;
    &_pass {
      margin-left: 32px;
    }
  }
  button {
    color: #fff !important;
    background: rgba(0, 0, 0, 0.1) !important;
    border-color: #fff !important;
    margin-right: 32px;
    z-index: 999999;
  }
  &_last {
    position: absolute;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-size: 16px;
    &_arrows {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 50px;
      height: 50px;
      margin-top: 0 !important;
    }
    img {
      margin-top: 20vh;
    }
    &_dec {
      font-size: 20px;
      margin-top: 30px;
    }
    &_remark {
      font-size: 17px;
    }
    &_btns {
      display: flex;
      margin-top: 30px;
      button {
        width: 120px;
        margin: 8px 8px !important;
        height: 40px;
      }
    }
  }
}
@media screen and (max-width: 1001px) {
  .guidance {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    overflow: hidden;
    &_dec {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      z-index: 999999;
      position: absolute;
      margin-top: 125px;
      margin-left: 100px;
    }
    &_arrows {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      z-index: 999999;
      margin-top: 100px;
      position: absolute;
      img {
        width: 68px;
        height: 68px;
      }
    }
    &_top {
      width: 100vw;
      // height: 100px;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      z-index: 999999;
    }
    &_left {
      height: 100vh;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      left: 0;
      z-index: 999999;
    }

    &_right {
      // height: 100px;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      z-index: 999999;
    }
    &_last {
      position: fixed;
      z-index: 999999;
      background: rgba(0, 0, 0, 0.6);
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #fff;
      img {
        margin-top: 20vh;
      }
      &_dec {
        font-size: 20px;
        margin-top: 30px;
      }
      &_remark {
        font-size: 17px;
      }
      &_btns {
        bottom: 30px;
        position: absolute;
        display: flex;
        flex-direction: column;
        button {
          width: 80vw;
          margin: 8px 0 !important;
        }
      }
    }

    &_bottom {
      // height: 100px;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      z-index: 999999;
      bottom: 0;
    }
    &_btns {
      position: absolute;
      margin-top: 100px;
      display: flex;
      justify-content: space-around;
      width: 100vw;
    }
    button {
      color: #fff !important;
      background: rgba(0, 0, 0, 0.1) !important;
      border-color: #fff !important;
      z-index: 999999;
      margin: 0;
    }
  }
}
