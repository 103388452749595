@import "~antd/dist/antd.css";
@import '~antd-mobile/dist/antd-mobile.css';
@import "//at.alicdn.com/t/font_1504093_9sb3wmyrctb.css";
html,
body,
#root {
  height: 100%;
}
* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
