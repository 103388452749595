.set-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  position: relative;
  z-index: 8;
  box-shadow: 0px 6px 7px 0px rgba(0, 0, 0, 0.08);
  // border-bottom     : 1px solid rgba(84,121,158,1);

  &__left {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__logo {
    // width      : 200px;
    // margin-left: 40px;
    width: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 8;
    background-color: rgba(67, 101, 135, 0.9);
    border-bottom: 1px solid rgba(84, 121, 158, 1);

    img {
      display: inline-block;
      width: 152px;
      height: 46px;
      color: #436587;
    }
  }

  &__tabs {
    height: 100%;
    font-size: 16px;
    color: #9c9c9c;
    display: flex;
  }

  &__tab {
    height: 100%;
    line-height: 80px;
    margin: 0 30px;
    transition: 0.3s;
    position: relative;

    &--selected {
      color: #436587;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 6px;
        background: #436587;
      }
    }

    cursor: pointer;

    &:hover {
      color: #436587;
    }
  }

  &__search {
    margin-right: 30px;
  }
}

@scale: 0.625;

@media screen and (max-width: 1001px) {
  .set-header {
    height: 80px * @scale;

    &__left {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
    &__logo {
      width: 100px;
      background: #fff;
      margin-left: 0px!important;
      border-bottom: none;

      img {
        display: inline-block;
        width: 152px * @scale;
        height: 46px * @scale;
        color: #436587;
      }
    }

    &__tabs {
      flex: 1;
      overflow-x: auto;
      height: 100%;
      font-size: 16px;
      color: #9c9c9c;
    }

    &__tab {
      // display: none;
      height: 100%;
      line-height: 80px * @scale;
      margin: 0 30px * @scale;
      transition: 0.3s;
      position: relative;
      min-width: 92px;
      text-align: center;

      &--selected {
        // color: #436587;
        color: #fff!important;
        position: absolute;
        right: 4px;
        display: block;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          width: 100%;
          height: 6px;
          left: 0;
          background: #436587;
        }
      }

      cursor: pointer;

      &:hover {
        color: #436587;
      }
    }

    &__search {
      margin-right: 30px * @scale;
    }
  }
}

@media screen and (max-width: 1023px) {
  .set-header {
    background: #4a6584;
    &__search {
      display: none;
    }
    &__logo{
      background: #4a6584;
    }
  }
}
