@import url(//at.alicdn.com/t/font_1504093_9sb3wmyrctb.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loadable-loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow: hidden;
}
.loadable-loading img {
  width: 100px;
  height: 100px;
  top: 30vh;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s;
}

.dev-tools__btn {
  z-index: 9999;
}

.set-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 80px;
  position: relative;
  z-index: 8;
  box-shadow: 0px 6px 7px 0px rgba(0, 0, 0, 0.08);
}
.set-header__left {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.set-header__logo {
  width: 240px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  z-index: 8;
  background-color: rgba(67, 101, 135, 0.9);
  border-bottom: 1px solid #54799e;
}
.set-header__logo img {
  display: inline-block;
  width: 152px;
  height: 46px;
  color: #436587;
}
.set-header__tabs {
  height: 100%;
  font-size: 16px;
  color: #9c9c9c;
  display: -webkit-flex;
  display: flex;
}
.set-header__tab {
  height: 100%;
  line-height: 80px;
  margin: 0 30px;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
}
.set-header__tab--selected {
  color: #436587;
}
.set-header__tab--selected::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 6px;
  background: #436587;
}
.set-header__tab:hover {
  color: #436587;
}
.set-header__search {
  margin-right: 30px;
}
@media screen and (max-width: 1001px) {
  .set-header {
    height: 50px;
  }
  .set-header__left {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .set-header__logo {
    width: 100px;
    background: #fff;
    margin-left: 0px!important;
    border-bottom: none;
  }
  .set-header__logo img {
    display: inline-block;
    width: 95px;
    height: 28.75px;
    color: #436587;
  }
  .set-header__tabs {
    -webkit-flex: 1 1;
            flex: 1 1;
    overflow-x: auto;
    height: 100%;
    font-size: 16px;
    color: #9c9c9c;
  }
  .set-header__tab {
    height: 100%;
    line-height: 50px;
    margin: 0 18.75px;
    transition: 0.3s;
    position: relative;
    min-width: 92px;
    text-align: center;
    cursor: pointer;
  }
  .set-header__tab--selected {
    color: #fff !important;
    position: absolute;
    right: 4px;
    display: block;
  }
  .set-header__tab--selected::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 6px;
    left: 0;
    background: #436587;
  }
  .set-header__tab:hover {
    color: #436587;
  }
  .set-header__search {
    margin-right: 18.75px;
  }
}
@media screen and (max-width: 1023px) {
  .set-header {
    background: #4a6584;
  }
  .set-header__search {
    display: none;
  }
  .set-header__logo {
    background: #4a6584;
  }
}

.navigation {
  width: 240px;
  padding-left: 40px;
  position: relative;
  background: #fff;
  transition: 0.3s;
}
.navigation__guidanceIcon {
  width: 16px;
  height: 16px;
}
.navigation h2 {
  margin-top: 10px;
  font-size: 24px;
  color: #fff;
  text-align: center;
}
.navigation__content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.navigation__content .navigation__item {
  width: 100%;
}
.navigation__item {
  display: block;
  font-size: 14px;
  text-align: center;
  padding-left: 0;
  color: #fff;
  height: 56px;
  line-height: 56px;
  transition: 0.3s;
  opacity: 1;
  visibility: visible;
}
.navigation__item--selected {
  background: #fff;
  color: #436587;
  position: relative;
}
.navigation__item--selected::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 100%;
  left: 0;
  top: 0;
  background: #436587;
}
.navigation__footer {
  position: absolute;
  left: 70px;
  bottom: 8px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 1001px) {
  .navigation {
    position: fixed;
    left: calc(100vw - 240px);
    width: 240px;
    z-index: 2;
    padding: 0;
    box-shadow: 0px 6px 7px 0px rgba(0, 0, 0, 0.08);
  }
  .navigation__unfold {
    left: 100vw !important;
    width: 0;
  }
  .navigation__item {
    padding-left: 30px;
  }
  .navigation__subItem {
    font-size: 16px;
    padding-left: 0;
  }
  .navigation__guidance {
    position: absolute;
    top: -34px;
    right: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .navigation__guidance img {
    width: 16px;
    height: 16px;
  }
  .navigation__h2 {
    display: block;
    width: 240px;
  }
  .navigation__h2--unfold {
    display: none;
  }
  .navigation__content {
    display: block;
    width: 240px;
    height: calc(100vh - 148px);
    overflow: auto;
  }
  .navigation__content--unfold {
    display: none;
  }
  .navigation__switch {
    position: absolute;
    right: 0;
    top: -46px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    transition: 0.3s;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 0 2px 2px 0;
  }
  .navigation__switch--unfold {
    text-align: right;
    padding-right: 10px;
  }
  .navigation__footer {
    position: static;
    position: initial;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 16px;
    height: 40px;
    color: #fff;
    cursor: pointer;
  }
  .navigation__footer--unfold {
    display: none;
  }
}

.calendar-layout {
  height: 100%;
}
.calendar-layout__content {
  height: calc(100vh - 80px);
  overflow: hidden;
  z-index: 99;
}
.calendar-layout__catalog {
  height: calc(100vh - 80px);
  margin-top: 80px;
  float: left;
  background-color: rgba(67, 101, 135, 0.9);
  position: absolute;
  top: 0;
  z-index: 10;
  color: #fff;
  padding-left: 0px;
  box-shadow: 0px 0px 43px 0px rgba(30, 63, 96, 0.68);
}
.calendar-layout__catalogContainer {
  height: 100vh;
  float: left;
  width: 240px;
  position: absolute;
  top: 0;
  background: url(/static/media/bg.32b72506.jpg);
  background-color: rgba(67, 101, 135, 0.9);
}
.calendar-layout__main {
  height: 100%;
  overflow: auto;
  padding: 0px 66px 56px;
  margin-left: 240px;
}
@media screen and (max-width: 1001px) {
  .calendar-layout__content {
    height: calc(100vh - 50px);
  }
  .calendar-layout__catalog {
    height: calc(100vh - 50px);
    margin-top: 50px !important;
    box-shadow: none;
    z-index: 1001;
  }
  .calendar-layout__main {
    padding: 0 !important;
    margin-left: 0 !important;
  }
  .calendar-layout__catalogContainer {
    width: 0;
  }
  .calendar-layout__cover {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.65);
    opacity: 0.3;
    z-index: 9;
    position: absolute;
  }
}

.guidance {
  width: 100vw;
  height: 100vh;
  left: 0;
  position: fixed;
  z-index: 9999999;
}
.guidance_dec {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  z-index: 999999;
  position: absolute;
}
.guidance_arrows {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  z-index: 999999;
  position: absolute;
}
.guidance_arrows img {
  width: 68px;
  height: 68px;
}
.guidance_top {
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 999999;
}
.guidance_left {
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  z-index: 999999;
}
.guidance_right {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 999999;
}
.guidance_bottom {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 999999;
  bottom: 0;
}
.guidance_btns {
  position: absolute;
}
.guidance_btns_pass {
  margin-left: 32px;
}
.guidance button {
  color: #fff !important;
  background: rgba(0, 0, 0, 0.1) !important;
  border-color: #fff !important;
  margin-right: 32px;
  z-index: 999999;
}
.guidance_last {
  position: absolute;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: #fff;
  font-size: 16px;
}
.guidance_last_arrows {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  margin-top: 0 !important;
}
.guidance_last img {
  margin-top: 20vh;
}
.guidance_last_dec {
  font-size: 20px;
  margin-top: 30px;
}
.guidance_last_remark {
  font-size: 17px;
}
.guidance_last_btns {
  display: -webkit-flex;
  display: flex;
  margin-top: 30px;
}
.guidance_last_btns button {
  width: 120px;
  margin: 8px 8px !important;
  height: 40px;
}
@media screen and (max-width: 1001px) {
  .guidance {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    overflow: hidden;
  }
  .guidance_dec {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    z-index: 999999;
    position: absolute;
    margin-top: 125px;
    margin-left: 100px;
  }
  .guidance_arrows {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    z-index: 999999;
    margin-top: 100px;
    position: absolute;
  }
  .guidance_arrows img {
    width: 68px;
    height: 68px;
  }
  .guidance_top {
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 999999;
  }
  .guidance_left {
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    z-index: 999999;
  }
  .guidance_right {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 999999;
  }
  .guidance_last {
    position: fixed;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    color: #fff;
  }
  .guidance_last img {
    margin-top: 20vh;
  }
  .guidance_last_dec {
    font-size: 20px;
    margin-top: 30px;
  }
  .guidance_last_remark {
    font-size: 17px;
  }
  .guidance_last_btns {
    bottom: 30px;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .guidance_last_btns button {
    width: 80vw;
    margin: 8px 0 !important;
  }
  .guidance_bottom {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 999999;
    bottom: 0;
  }
  .guidance_btns {
    position: absolute;
    margin-top: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100vw;
  }
  .guidance button {
    color: #fff !important;
    background: rgba(0, 0, 0, 0.1) !important;
    border-color: #fff !important;
    z-index: 999999;
    margin: 0;
  }
}

.reserve-calendar {
  height: 100%;
  background: #fff;
}

.share-calendar {
  height: 100%;
  background: #fff;
}

html,
body,
#root {
  height: 100%;
}
* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

